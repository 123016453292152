import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/RegistrationForm.scss";
import Select from "react-select";
import { getNames } from "country-list";
import config from "../app_config";
const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const RegistrationForm = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const checkPassword = async (tripSelection, password) => {
    if (!tripSelection || tripSelection === "Select a trip") {
      toast.error(
        "Please select a trip from the drop-down menu before verification.",
        toastOptions
      );
      return;
    }

    try {
      const response = await axios.post(
        `http://${config.hostname}:${config.port}/check-password`,
        { tripSelection, password }
      );
      if (response.data.valid) {
        setPasswordValid(true);
        setPasswordError("");
        toast.success(
          "Verification successful! You're good to go. Please proceed with filling out the rest of the form.",
          toastOptions
        );
      } else {
        setPasswordValid(false);
        setPasswordError("Incorrect password");
        toast.error("Incorrect password. Please try again.", toastOptions);
      }
    } catch (error) {
      console.error(error);
      setPasswordValid(false);
      setPasswordError("Incorrect password");
      toast.error("Incorrect password. Please try again.", toastOptions);
    }
  };

  const onSubmit = async (data) => {
    // Ensure we only submit the value of the country field
    const submissionData = { ...data, country: data.country.value };

    try {
      const response = await axios.post(
        `http://${config.hostname}:${config.port}/register`,
        submissionData
      );
      if (response.status === 200) {
        setPasswordValid(false);
        setPasswordError("");
        reset(); // Initial reset for all fields
        reset({
          country: "", // Override reset for country to default
        });
        toast.success(
          "Submission successful! Thank you for registering.",
          toastOptions
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Error submitting form. Please try again.", toastOptions);
    }
  };

  const tripSelection = watch("tripSelection");
  const tripPassword = watch("tripPassword");

  // Define a custom country list with simplified names and additional entries
  const customCountryList = [
    ...getNames()
      .map((name) => ({ value: name, label: name }))
      .filter(
        (option) =>
          option.label !==
          "United Kingdom of Great Britain and Northern Ireland"
      ),
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "Northern Ireland", label: "Northern Ireland" },
  ].sort((a, b) => a.label.localeCompare(b.label));

  // Add United Kingdom and China to the top of the list
  customCountryList.unshift({
    value: "United Kingdom",
    label: "United Kingdom",
  });
  // Add a separator after China
  // customCountryList.splice(2, 0, { value: "", label: "----------" });
  customCountryList.splice(1, 0, {
    value: "",
    label: "----------",
    isDisabled: true,
  });

  // Function to clear password field
  const clearPasswordField = () => {
    setValue("tripPassword", "");
    setPasswordValid(false);
  };

  return (
    <div>
      <div className="form-container">
        <ToastContainer />
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Camp Registration Form</h2>
          <fieldset>
            <legend>营地信息 Camp information</legend>
            <table>
              <tbody>
                <tr>
                  <td>选择行程 Trip selection:</td>
                  <td>
                    <select
                      {...register("tripSelection", { required: true })}
                      onChange={(e) => {
                        setPasswordValid(false);
                        clearPasswordField();
                        setValue("tripSelection", e.target.value);
                      }}
                    >
                      <option value="">选择行程 Select a trip</option>
                      <option value="Beijing-Xian">
                        北京-西安 Beijing-Xian
                      </option>
                      <option value="Beijing-Shanxi">
                        北京山西 Beijing-Shanxi
                      </option>
                    </select>
                    {errors.tripSelection && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    请输入密码 <br />
                    Provide password for trip:
                  </td>
                  <td>
                    <input
                      type="password"
                      {...register("tripPassword", { required: true })}
                    />
                    {errors.tripPassword && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button
                      type="button"
                      className="verify-button"
                      onClick={() => checkPassword(tripSelection, tripPassword)}
                    >
                      Verify
                    </button>
                    {passwordError && (
                      <span className="error-message">{passwordError}</span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset disabled={!passwordValid}>
            <legend>基本信息 Basic information</legend>
            <table>
              <tbody>
                <tr>
                  <td>是否为领队 Team leader:</td>
                  <td>
                    <input {...register("teamLeader", { required: false })} />
                    {errors.teamLeader && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>国籍 Citizenship:</td>
                  <td>
                    <input {...register("citizenship", { required: true })} />
                    {errors.citizenship && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>护照号 Passport number:</td>
                  <td>
                    <input
                      {...register("passportNumber", { required: true })}
                    />
                    {errors.passportNumber && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>中文名 Chinese name:</td>
                  <td>
                    <input {...register("chineseName", { required: true })} />
                    {errors.chineseName && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>英文名 English first name:</td>
                  <td>
                    <input
                      {...register("englishFirstName", { required: true })}
                    />
                    {errors.englishFirstName && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>英文姓 English last name:</td>
                  <td>
                    <input
                      {...register("englishLastName", { required: true })}
                    />
                    {errors.englishLastName && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>性别 Gender:</td>
                  <td>
                    <select
                      id="gender"
                      {...register("gender", { required: true })}
                    >
                      <option value="">选择性别 Select gender</option>{" "}
                      <option value="Male">男 Male</option>{" "}
                      <option value="Female">女 Female</option>
                    </select>

                    {errors.gender && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>生日 Birthday:</td>
                  <td>
                    <input
                      type="date"
                      {...register("birthday", { required: true })}
                    />
                    {errors.birthday && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>所在国家 Country:</td>
                  <td>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={customCountryList}
                          className="react-select-container"
                          classNamePrefix="react-select"
                        />
                      )}
                    />
                    {errors.country && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>所在州/省 State / Province:</td>
                  <td>
                    <input {...register("stateProvince", { required: true })} />
                    {errors.stateProvince && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>所在城市 City:</td>
                  <td>
                    <input {...register("city", { required: true })} />
                    {errors.city && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>通信地址 Address:</td>
                  <td>
                    <input {...register("address", { required: true })} />
                    {errors.address && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>电子邮箱 Email:</td>
                  <td>
                    <input
                      type="email"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>在读学校 School:</td>
                  <td>
                    <input {...register("school", { required: true })} />
                    {errors.school && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset disabled={!passwordValid}>
            <legend>身型信息 Physical measurements</legend>
            <table>
              <tbody>
                <tr>
                  <td>身高 Height:</td>
                  <td>
                    <input {...register("height", { required: true })} />
                    {errors.height && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>体重 Weight:</td>
                  <td>
                    <input {...register("weight", { required: true })} />
                    {errors.weight && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>衣码 Clothing size:</td>
                  <td>
                    <input {...register("clothingSize", { required: true })} />
                    {errors.clothingSize && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset disabled={!passwordValid}>
            <legend>附加信息 Additional information</legend>
            <table>
              <tbody>
                <tr>
                  <td>宗教信仰 Religion:</td>
                  <td>
                    <input {...register("religious", { required: true })} />
                    {errors.religious && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>饮食禁忌 Dietary restrictions:</td>
                  <td>
                    <input {...register("dietaryTaboos", { required: true })} />
                    {errors.dietaryTaboos && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>重大病史 Medical history:</td>
                  <td>
                    <input
                      {...register("significantMedicalHistory", {
                        required: true,
                      })}
                    />
                    {errors.significantMedicalHistory && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>过敏史 Allergies:</td>
                  <td>
                    <input {...register("allergies", { required: true })} />
                    {errors.allergies && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset disabled={!passwordValid}>
            <legend>监护人信息 Guardian information</legend>
            <table>
              <tbody>
                <tr>
                  <td>中文名 Chinese name:</td>
                  <td>
                    <input
                      {...register("guardianChineseName", { required: true })}
                    />
                    {errors.guardianChineseName && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>英文名 English first name:</td>
                  <td>
                    <input
                      {...register("guardianEnglishFirstName", {
                        required: true,
                      })}
                    />
                    {errors.guardianEnglishFirstName && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>英文姓 English last name:</td>
                  <td>
                    <input
                      {...register("guardianEnglishLastName", {
                        required: true,
                      })}
                    />
                    {errors.guardianEnglishLastName && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>电话 Telephone:</td>
                  <td>
                    <input
                      {...register("guardianTelephone", { required: true })}
                    />
                    {errors.guardianTelephone && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>手机 Mobile phone:</td>
                  <td>
                    <input
                      {...register("guardianMobilePhone", { required: true })}
                    />
                    {errors.guardianMobilePhone && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>电子邮箱 Email:</td>
                  <td>
                    <input
                      type="email"
                      {...register("guardianEmail", { required: true })}
                    />
                    {errors.guardianEmail && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>微信名字 WeChat name:</td>
                  <td>
                    <input
                      {...register("guardianWeChatName", { required: true })}
                    />
                    {errors.guardianWeChatName && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                  <tr></tr>
                </tr>
                <tr>
                  <td>微信号码 WeChat ID:</td>
                  <td>
                    <input
                      {...register("guardianWeChatNameId", { required: true })}
                    />
                    {errors.guardianWeChatNameId && (
                      <span className="error-message">
                        This field is required
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          {/* <ReCAPTCHA sitekey="YOUR_RECAPTCHA_SITE_KEY" /> */}
          <button type="submit" disabled={!passwordValid}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
